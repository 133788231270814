.entries{
    position: relative;
    color: grey;
    left:16px;
    top:0px;
}


table{
    border-collapse:separate !important;
    border-spacing: 0 5px;
    text-align: center;
}


th{
   
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    
}
.lastEl:last-child {
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
}



.listL{

    background-color: rgb(245, 245, 245);
}

.listL:hover{
    background-color: rgb(255, 255, 255);
}
.head:hover{
    background-color: rgb(245, 245, 245) !important;
}

.bal{
    position:relative;
    left:15px;
}