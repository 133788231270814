body {
    background-color: white !important;
  }
  
  
.articles-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .article {
    width: 30%;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-size: 0.9rem;
  }
  
  .article img {
    display: block;
    width: 100%;
    max-width: 375px;
    height: 155px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .article h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    margin-left: 10px !important;
  }
  
  .article p {
    margin-bottom: 10px;
  }
  
  .article button {
    background-color: #01aab3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 40px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-left: 10px !important;
    margin-bottom: 19px !important;
  }
  
  .article button:hover {
    background-color: #01aab3;
  }
  
  .articles-page h1 {
    text-align: center;
  }
  
  .articles-page p {
    text-align: center;
  }
  
  @media only screen and (max-width: 1200px) {
    .articles-list {
      max-width: 960px;
    }
  }
  
  @media only screen and (max-width: 960px) {
    .articles-list {
      max-width: 640px;
    }
    .article {
      width: 45%;
    }
  }
  
  @media only screen and (max-width: 640px) {
    .articles-list {
      max-width: 320px;
    }
    .article {
      width: 100%;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination li {
    list-style: none;
    margin-right: 10px;
  }
  
  .pagination li a {
    text-decoration: none;
    color: #333;
    font-size: 1.2rem;
    transition: all 0.3s ease-in-out;
  }
  
  .pagination li.active a {
    font-weight: bold;
    color: #01aab3;
  }
  .paymentBG {
    background: #fff !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .article-text {
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    text-align: inherit !important;
    margin-bottom: 15px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
   .h1 {
    margin-top: 40px !important;
    color: #01aab3 !important;
    font-weight: 700 !important;
    margin-bottom: 20px !important; 
    font-size: 50px !important;
      }

      .h1des {
        margin-bottom: 40px !important;
        font-family: 'Roboto';
    font-weight: 500;
      }

    
