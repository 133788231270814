@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Amaranth&family=Exo+2:wght@100;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

/* Responsive css  */
@media only screen and (min-width: 320px) and (max-width: 992px) {
  .balance {
    width:unset !important;
    height:unset !important;
}
.nav-item1 {
  text-align: center !important;
}
}

@media only screen and (min-width: 350px) and (max-width: 480px) {

  .tabs-design .MuiTabs-root .MuiButtonBase-root.MuiTab-root {
    padding: 0 5px !important;
  }
  .tabs-design .MuiTabs-flexContainer.MuiTabs-flexContainer {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column;
}
.tabs-design {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs-design span.MuiTabs-indicator.MuiTabs-indicator {
  display: none;
}
.active-tab {
  border-bottom: 2px solid !important;
}
.servicelist-height {
  height: 720px !important;
}
.balance {
  width:unset !important;
  height:unset !important;
}
.search-country-input {
max-width: unset !important;
}
.newLogo {
  width: 160px !important;
  right: 6px !important;
}
}

@media only screen and (max-width: 767px) {
  .mobile-screen {
    padding: 0 !important;
  }
  .Homepage {
margin-bottom: 15px;
}
.instructions-main {
  padding-bottom: 0 !important;
}
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .btn-crypto{
    display: flex;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .payments img {
    width: auto;
    height: auto;
    max-width: 100%;
}
}
@media only screen and (min-width: 992px) and (max-width: 1260px) {
  .narBarBtn {
    margin-left: unset !important;
    margin-right: unset !important;
}
.telegram {
  left: -21px !important;
  margin: right 10px;;
}
.narBarBtn-new{
  font-size: 14px !important;
}
}
