.form-modal {
  text-align: start;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(192, 192, 192, 0.7) !important;
}

.margin-left-10 {
  margin-left: 10px;
}
.titre {
  font-size: 31px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-10 {
  margin-bottom: 5px;
}

.background-grey {
  background-color: rgb(12, 126, 126) !important;
}

._loading_overlay_content {
  top: 45%;
  left: 35%;
  position: fixed;
}

.logo {
  width: 220px;
  height: 27px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 550;
  font-size: 23px;
  line-height: 27px;
  background-color: transparent !important;
  text-decoration: none;
  color: #00a990;
}

.account {
  position: absolute;
  width: 69px;
  height: 20px;
  left: 1500px;
  top: 38px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;
}

.vector2 {
  position: absolute;
  width: 14.55px;
  height: 14.45px;
  left: 1475px;
  top: 25px;

  /* Primary */

  background: #00a990;
}

.vector1 {
  position: absolute;
  width: 25.19px;
  height: 15.51px;
  left: 1470px;
  top: 39.49px;

  /* Primary */

  background: #00a990;
}

.group {
  position: absolute;
  width: 102px;
  height: 37px;
  left: 1533px;
  top: 21px;
}

.signin {
  position: absolute;
  width: 50px;
  height: 15px;
  left: 1500px;
  top: 21px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  /* identical to box height */

  color: #000000;

  opacity: 0.5;
}

.messaging_service {
  width: 132px;
  height: 15px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.15em;

  color: #858585;
}

.new_navbar {
  position: absolute;
  width: 100%;
  /* height: 81px; */
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px -7px 66px rgba(0, 0, 0, 0.08);
}

.closButton {
  /* position: absolute; */
  left: 64.47%;
  right: 34.54%;
  top: 25.31% !important;
  bottom: 68.18%;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  margin-left: 175px;
}

.signup {
  margin-bottom: 15px;
  font-size: 18px;
  margin-bottom: 18px;
}

.btnColor {
  background-color: #00a990 !important;
  border-color: #00a990 !important;
  width: 430px;
}

.btnColor2 {
  background-color: #00a990 !important;
  border-color: #00a990 !important;
  width: 525px;
}

.linkSignUp {
  background-color: transparent !important;
  color: #0e7ee4;
  text-align: center;
  font-family: "Montserrat";
  font-size: 18px;
}
a.linkSignUp {
  margin-bottom: -3px;
  margin-top: 10px;
}
.inputField {
  background: #f8f8f8 !important;
  border-radius: 80px !important;
}

.inputField2 {
  background: #f8f8f8 !important;
  border-radius: 80px !important;
  margin-bottom: 25px;
}

.text-align-center {
  text-align: center;
}

.narBarBtn {
  /* height: 17px; */
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  border: none !important;
  color: #fff !important;
  text-decoration: none;
  font-size: 16px;
  background: #00a990;
  padding: 8px 12px;
  border-radius: 30px;
  display: inline-block;
  width: 100%;
}

.nav-item {
  margin: 10px;
  border-radius: 30px;
  text-align: center;
  background-color: #00a990;
  position: relative;
  bottom: -3px;
}
.nav-item1 {
  margin: 10px;
  border-radius: 30px;
  text-align: left;
}

.margin-right-150 {
  margin-right: 150px;
}

.narBarBtn3 {
  position: absolute !important;
  width: 150px;
  height: 17px;
  left: 305px;
  top: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  border: none !important;
}

.narBarBtn2 {
  position: absolute !important;
  width: 150px;
  height: 17px;
  left: 550px;
  top: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border: none !important;
}

.underline {
  position: absolute;
  width: 79px;
  height: 3px;
  left: 295px;
  top: 54px;

  /* Primary */

  background: #00a990;
  border-radius: 2px;
}

.btn.active {
  text-decoration-thickness: 5px;

  text-decoration-skip-ink: none;
}

.balance {
  box-sizing: border-box;
  width: 80px;
  height: 39px;
  margin: 10px;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
}

.margin-top {
  margin-top: 2px !important;
}

.profile {
  vertical-align: 0% !important;
  left: 10px;
}

.bg-custom-1 {
  background-color: #85144b;
}

.bg-custom-2 {
  background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
}

.margin-right-10 {
  margin-right: 15px;
}

.margin-right-45 {
  margin-right: 45px;
}

.margin-all {
  margin-top: 15px !important;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.wdith {
  width: 1070px;
  margin-left: 50px;
}

.margin-left-60 {
  margin-left: 60px !important;
}

.margin-right-100 {
  margin-right: 100px !important;
}

.balanceNew {
  text-decoration: none;
  position: relative;
  right: 40px !important;
}

.navExtended {
  /* background-color: white;
  text-align: end; */
  z-index: 99;
}

.balanceNew {
  position: relative;
  border: 1px solid #ddd;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  justify-content: center;
  border-radius: 6px;
  bottom: 2px;
}

.balanceNew:hover,
.balanceNew.active {
  border-color: #00a990;
  background: #fff;
}

.balance_amount {
  color: #00a990;

  width: 40px;
}

.depo {
  position: relative;
  right: 90px;
}

.newLogo {
  position: relative;
  width: 200px;
  height: 72px;
  bottom: 20px;
  right: 15px;
}

.hd {
  position: relative;
  left: 100px;
}

.dolsCSS {
  /* Vector */
  position: absolute;
  right: 40px;
  top: -7px;
  font-size: 25px;
  /* Primary */
  color: #00a990;
  border-radius: 8px;
}

.sld {
  text-align: left;
  width: 32px;
  color: #000000;
}

.locSet {
  background-color: white !important;
  position: relative;
  left: 14px;
  text-decoration: none;
  color: black;
}
.locSet:hover {
  color: black;
}

.log {
  position: relative;
  left: 20% !important;
}

.auth-modal-header-text {
  font-weight: 700;
  /* text-shadow: 0 2px 3px rgba(0, 0, 0, 0.5); */
}

button.closButton.btn-close {
  width: 0.5em;
  height: 0.5em;
  position: relative;
  left: 0 !important;
  top: 0 !important;
  z-index: 1;
}

i.eye {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  padding-right: 24px;
  cursor: pointer;
  color: #6c757d;
}

.hcaptcha_login_error {
  color: rgba(255, 0, 0, 1);
}

.hcaptcha {
  text-align: center;
}

.telegram {
  width: 40px;
}

@media (max-width: 992px) {
  .telegram {
    position: static;
  }
}

@media (max-width: 320px) {
  .newLogo {
    position: relative;
    width: 140px;
    height: 65px;
    bottom: 15px;
    right: 6px;
  }
}

.loginsec input {
  height: 45px;
  border: none;
  padding: 0 20px;
  border-radius: 8px !important;
  background: #f8f8f8 !important;
}
.Logbtn button {
  width: 160px !important;
  margin: 0px 0 0px;
  font-size: 20px;
  font-family: "Montserrat";
  border-radius: 110px;
}
.Logbtn {
  text-align: center !important;
}

.logwith {
  padding: 15px 0;
}
.logwith {
  display: flex;
  align-items: center;
  justify-content: center;
}
span.logtestdiv {
  width: 25%;
  border-top: 1px solid #ddd;
  margin: 0 10px 0;
}
span.logtest {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.btnColor {
  background-color: #00abb3 !important;
  border-color: #00abb3 !important;
  width: 430px;
}
.social-signup-icons button {
  background: transparent;
  border: unset;
  padding: 0px;
}
/* .googleicon {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
} */
.form-control:focus {
  box-shadow: unset;
}

.logintitle {
  margin-left: 30px;
  font-size: 40px;
  margin-bottom: 8px;
}

.inputField {
  margin-bottom: 20px !important;
}

.login i.eye {
  top: -10px;
}
.same-dropdown {
  color: #333 !important;
  text-decoration: none !important;
  display: block !important;
  padding: 8px 10px !important;
  font-size: 16px !important;
}

.balance-adjust .balance_amount {
  color: #00a990;
  min-width: 40px !important;
  width: unset;
}

.balance-adjust {
  width: auto;
}
