.title_rent {
    font-family: "Exo 2", sans-serif;
    font-size: 37px;
    font-weight: 700px;
    font-weight: bold;
    line-height: 44px;
    margin-top: 60px;
    margin-bottom: -20px;
    margin-left: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #00abb3;
    display: flex;
  }
  .title-history {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    padding-top: 20px;
    text-align: center;
    margin-bottom: 30px;
    font-size: 25px;
    color: #231f20;
  }
  /* Style pour le bouton inactif (Gris) */
  .btn.service-buy {
    background-color: #c0c0c0; /* Gris */
    color: white; /* Couleur du texte */
    border: none; /* Pas de bordure */
    padding: 6px 12px; /* Ajustez la taille du bouton */
    font-size: 12px; /* Taille de la police */
    font-weight: bold;
    border-radius: 12px; /* Coins arrondis */
    cursor: pointer; /* Curseur en forme de main lors du survol */
    transition: background-color 0.3s; /* Transition douce pour le changement de couleur */
    margin-left: 10px;
  }
  
  /* Style pour le bouton actif (Bleu) */
  .btn.service-buy.active {
    background-color: #007bff; /* Bleu */
    color: white; /* Couleur du texte */
    /* Les autres propriétés sont héritées du style .btn.service-buy */
  }
  
  #cancel{
    background-color:red;
  }
  .rentBG {
    background: #fff;
    min-height: calc(100vh - 160px);
    display: flex;
    /* stack the content vertically */
  }
  
  .row {
    display: flex;
    justify-content: center;
  }
  .box_please {
    /* Testimonial /
  
  
  / Auto layout /
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 40px;
  justify-content: center;
  position: absolute;
  width: 1099px;
  height: 836px;
  left: 170px;
  top: 271px;
  
  background: #FFFFFF;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  
  }
  .title_free_scnd{
  / Body /
  
  
  position: absolute;
  width: 115px;
  height: 27px;
  left: 229px;
  top: 307px;
  flex-direction: column;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 140%;
  / identical to box height, or 27px /
  
  
  color: #00ABB3;
  }
  .text_free{
  flex-direction: column;
  position: absolute;
  width: 985px;
  height: 229px;
  left: 227px;
  top: 354px;
  
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 45px;
  / or 281% */
  
    text-align: justify;
    color: #333333;
  }
  
  body {
    background: #fff !important;
  }
  
  .sub_title {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: #4b4d4f;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .title_free_scnd {
    font: 600 19px/25px "Exo 2", sans-serif;
    color: #00abb3;
  }
  
  .text_free {
    font: 400 16px/40px "Roboto", sans-serif;
    color: #333333;
  }
  
  .danger-text {
    color: #bc1616;
  }
  
  .box_rent {
    border: none !important;
    border-radius: 10px !important;
    padding: 40px;
    padding-bottom: 50px;
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px 300px;
    gap: 2.5rem;
  }
  
  .title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    font-size: 25px;
    color: #231f20;
  }
  .title-low {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 250px;
    font-size: 17px;
    margin-bottom: 30px;
    color: #231f20;
  
  }
  .NoDataHistory{
      font-family: "Roboto", sans-serif;
      text-align: center;
      margin-top: 50px;
  }
  .NoDataHistory h1{
      font-size: 30px;
      font-weight: 400px;
  }
  
  .NoDataHistory span{
      font-size: 15px;
      font-weight: 200px;
  }
  .NoDataHistory img{
      margin-bottom: 15px;
  }
  .val {
    font-weight: bold;
  }
  .NoDataPhone{
      font-family: "Roboto", sans-serif;
      margin-top: 80px;
      text-align: center;
  }
  .NoDataPhone img{
      margin-bottom: 15px;
  }
  .NoDataPhone span{
      font-size: 15px;
      font-weight: 200px;
  }
  .NoDataPhone h1{
      font-size: 30px;
      font-weight: 400px;
  }
  
  
  .rent_main {
    grid-area: 1/1;
    grid-row: span 1;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .price {
    font-weight: bold;
  }
  .countries_main {
    border-top: 4px solid #00abb3;
    border-right: 4px solid #00abb3;
    border-radius: 10px;
    height: 100%;
    box-shadow: -5px 0 0 -3px rgba(0, 0, 0, 0.2),
      /* Ombre sur le bord gauche */ 0 6px 0 -3px rgba(0, 0, 0, 0.3); /* Ombre sur le bord inférieur */
  }
  
  .countries {
    border-right: 1px solid #d9d9d9;
  }
  .countries_main ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .countries_main > * {
    height: 100%;
  }
  .search-countries-input {
    font-family: "Roboto", sans-serif;
    border: 1px solid #d9d9d9;
    border-radius: 10px !important;
    background: #ffffff;
    color: #231f20;
    font-weight: 400;
    font-size: 13px;
    margin: 10px;
    max-width: 260px;
    width: 85%;
    height: 30px;
    padding: 20px 0px 20px 20px;
    text-transform: capitalize;
  }
  
  .countries_main .list {
    overflow-y: auto;
    padding: 10px;
    max-height: 400px !important;
  }
  
  .countries_main .list li {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 60px;
  }
  .countries_main .countries_list {
    border-right: 1px solid #d9d9d9;
  }
  .countries_main .countries_list li {
    border-bottom: 1px solid #C7C3C3;
  
  }
  .countries_main .countries_list li:last-child{
      border-bottom: none;
  }
  .list.countries_list .country.active {
      background: #198FFB;
      color: white;
    }
  
  .country {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 90%;
  }
  
  .flag_icon {
    width: 45px;
    height: 35px;
    display: block;
    box-shadow: 0 1.5px 1.5px rgba(0, 0, 0, 0.1);
    margin: 0px 10px 0px 5px;
  }
  
  .country .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  .country .content > * {
    font-family: "Amaranth";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;
  }
  .country .number {
    display: flex;
    justify-content: right;
  }
  
  .range_choice {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
  
    border-radius: 2px;
    padding: 10px 10px;
    margin-top: 0px !important;
    cursor: pointer;
    gap: 10px;
  }
  .RangeActive .range_choice {
    background: #f0f2f6;
  }
  
  .range_infos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .range_infos > * {
    font-family: "Roboto";
    font-style: normal;
    font-size: 17px;
    line-height: 14px;
    color: #333333;
  }
  
  h2 {
    font: 600 25px/25px "Exo 2", sans-serif;
    color: #00abb3;
    font-weight: bold;
  }
  /*RIGHT COLUMN*/
  .received_sms_main {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .received_sms_main h2,
  .rent_history_main h2 {
    display: flex;
    justify-content: left;
  }
  .received_sms_main button,
  .rent_history_main button {
    background: none;
    border: none;
    outline: none;
    font-size: 22px;
    line-height: 0;
    height: 19px;
  }
  
  .rented_numbers_main {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1.5px solid #00abb3;
    border-radius: 10px;
    height: 100%;
    max-height: 400px;
  }
  
  .rented_numbers_main ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .rented_numbers_main .rented_numbers_list {
    overflow-y: auto;
    max-height: 350px;
    margin-bottom: 0;
    border-right: 1px solid #d9d9d9;
  }
  
  .rented_numbers_main .rented_numbers_list li {
    display: flex;
    padding: 10px 5px 10px 0;
    border-bottom: 1px solid rgba(217, 217, 217, 0.4);
    align-items: center;
    cursor: pointer;
  }
  
  
  .rented_numbers_main .numbers_list li:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  .rented_numbers_main .numbers_list {
    padding: 10px 20px;
    border-right: 1px solid #d9d9d9;
    height: 100%;
  }
  
  .flag_icon {
    width: 35px;
    height: 23px;
    display: block;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
  }
  
  .flag_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  .rented_numbers_main .rented_numbers_list li{
      margin: 10px 10px;
  }
  .rented_numbers_main .rented_numbers_list li.active {
    background-color: #198FFB;
    color: white;
    border-radius: 10px;
  }
  
  .NoMessageFound{
    width: 100%;
    text-align: center;
    padding-top: 20%;

  }
  .rented_number_header {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 15px 5px 15px 15px;
    border: 1px solid #ece9e9;
    justify-content: space-between;
  }
  
  .rented_number_header .left,
  .rented_number_header .right {
    display: flex;
    align-items: flex-end;
  }
  
  .rented_number_header .left {
    font: 500 15px/15px "Roboto", sans-serif;
    color: #000;
  }
  
  .select_rented_number.empty .rented_number_header .left * {
    display: none;
  }
  
  .select_rented_number.empty .rented_number_header .left {
    padding-bottom: 25px;
    width: 130px;
    border-radius: 999px;
    background: #f0f0f0;
  }
  
  .select_rented_number.empty .rented_number_header .right * {
    display: none;
  }
  
  .select_rented_number.empty .rented_number_header .right {
    padding-bottom: 25px;
    width: 90px;
    margin-right: 10px;
    border-radius: 999px;
    background: #f0f0f0;
  }
  
  .rented_number_header .left button {
    background: none;
    display: block;
    border: none;
    outline: none;
    line-height: 0;
    height: 19px;
  }
  
  .rented_number_header .left button i {
    line-height: 0;
    height: 19px;
  }
  
  .select_rented_number {
    padding: 15px 15px 0px 0px;
  }
  
  .messages ul {
    overflow-y: auto;
    height: 280px;
  }
  
  .messages ul::-webkit-scrollbar,
  .rented_numbers_main .numbers_list::-webkit-scrollbar {
    width: 5px;
  }
  
  .messages ul::-webkit-scrollbar-thumb,
  .rented_numbers_main .numbers_list::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 100px;
  }
  
  .rented_number_header .right .phone_number {
    line-height: 22px;
  }
  
  .message_item {
    padding: 20px 20px 20px 10px;
    display: flex;
    border-bottom: 1px solid rgba(217, 217, 217, 0.4);
  }
  
  .message_item:last-child {
    border-bottom: none;
  }
  
  .message_item .icon {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background: #f0f0f0;
  }
  
  .message_item .content {
    width: calc(100% - 31px);
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding-left: 15px;
  }
  
  .message_item .content .left {
    font-size: 15px;
  }
  
  .message_item .content .left .from {
    font-family: "Amaranth", sans-serif;
    color: #000;
    font-size: 14px;
    margin-bottom: 7px;
  }
  
  .select_rented_number.empty .message_item .content .left .from {
    width: 80px;
    padding-bottom: 15px;
    background: #f0f0f0;
    border-radius: 999px;
    margin-bottom: 12px;
  }
  
  .select_rented_number.empty .message_item .content .left .body {
    width: 120px;
    padding-bottom: 20px;
    background: #f0f0f0;
    border-radius: 999px;
  }
  
  .message_item .content .date {
    font-family: "Roboto", sans-serif;
    color: #000;
  }
  
  .message_item .content .date {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    color: #000;
  }
  
  .message_item .content .date {
    margin-bottom: 7px;
  }
  
  .select_rented_number.empty .message_item .content .date {
    width: 70px;
    padding-bottom: 15px;
    background: #f0f0f0;
    border-radius: 999px;
    margin-bottom: 12px;
  }
  
  .select_rented_number.empty .message_item .content .right .code {
    width: 70px;
    padding-bottom: 20px;
    background: #f0f0f0;
    border-radius: 999px;
  }
  
  .message_item .content .right .code span {
    font-weight: 700;
    color: #2329c3;
  }
  
  .rented_messages ul::-webkit-scrollbar,
  .rented_numbers_main ul::-webkit-scrollbar,
  .rent_history ul::-webkit-scrollbar,
  .stats .datas ul::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  .rented_messages ul::-webkit-scrollbar-thumb,
  .rented_numbers_main ul::-webkit-scrollbar-thumb,
  .rent_history ul::-webkit-scrollbar-thumb,
  .stats .datas ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 100px;
  }
  
  /*RENT HISTORY*/
  .rent_history_main {
    display: flex;
    flex-direction: column;
    grid-area: 2/2;
    gap: 10px;
  }
  
  .rent_history {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1.5px solid #00abb3;
    border-radius: 10px;
    display: flex;
    width: 100%;
    height: 265px;
  }
  .stats {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 4fr;
  }
  
  .stats .headers {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    border-bottom: 1px solid #d4d5d8;
  }
  .stats .headers span {
    display: flex;
    justify-content: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #000000;
  }
  .stats .headers span:last-child,
  .stats .datas li span:last-child {
    color: #00abb3;
  }
  
  .stats .datas ul {
    height: 100%;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    height: 208px;
    overflow-y: auto;
  }
  .stats .datas ul li {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding: 5px 0px;
    color: #000000;
    border-bottom: 1px dashed #d4d5d8;
  }
  .stats .datas ul li span {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  
  @media screen and (max-width: 350px) {
    .stats {
      grid-template-rows: none;
      grid-template-columns: 1fr 4fr;
    }
    .stats .headers {
      grid-template-columns: none;
      grid-template-rows: repeat(5, 1fr);
    }
    .rent_main{
      min-height: 760px;

    }
    .stats .datas {
      overflow-x: auto;
    }
    .stats .datas ul {
      display: flex;
    }
    .stats .datas ul li {
      display: grid;
      grid-template-columns: none;
      grid-template-rows: repeat(5, 1fr);
      border-right: 1px dashed #7d7d7f;
    }
  }
  @media screen and (max-width: 575.98px) {
    .box_rent {
      padding: 7px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
    }
    .lowres{
      position: relative;
      bottom:350px;
    }
    .title-low{
      position: relative;
      top:20px;
      left:35px;
    }
  
    .rented_numbers_main {
      margin: 0px;
    }
  
    .rented_rented_number_header {
      display: block;
      margin-bottom: 15px;
      border-radius: 0;
    }
  
    .rented_rented_number_header .left {
      margin-bottom: 10px;
    }
  
    .rented_rented_number_header .right {
      justify-content: flex-end;
    }
  
    .rented_rented_number_header .right .flag_icon {
      margin-right: 5px;
    }
  
    .select_rented_number {
      padding-right: 0;
    }
    .rented_messages ul {
      overflow-y: auto;
      max-height: 880px;
    }
    .message_item .content {
      display: block;
    }
  
    .message_item .content .left .body {
      margin-bottom: 10px;
    }
    .rent_main{
      min-height: 760px;

    }
    .message_item .content .date {
      margin-bottom: 0;
    }
  
    .rented_numbers_main {
      max-height: 1000px;
    }
  
    .rented_numbers_main .numbers_list {
      border-right: none;
    }
  
    .numbers_list {
      max-height: 300px !important;
    }
  }
  
  @media (min-width: 576px) and (max-width: 767px) {
    .box_rent {
      padding: 7px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
    }
    .lowres{
      position: relative;
      bottom:350px;
    }
    .title-low{
      position: relative;
      top:20px;
      left:35px;
    }
  
    .rented_numbers_main {
      margin: 0px;
      max-height: 750px;
    }
  
    .rented_number_header {
      display: block;
      margin-bottom: 15px;
      width: 100%;
      border-radius: 0;
    }
    .rent_main{
      min-height: 760px;

    }
    .rented_number_header .left {
      margin-bottom: 10px;
    }
  
    .rented_number_header .right {
      justify-content: flex-end;
    }
  
    .rented_number_header .right .flag_icon {
      margin-right: 5px;
    }
  
    .select_rented_number {
      padding-right: 0;
    }
  
    .rented_messages ul {
      overflow-y: auto;
      max-height: 880px;
    }
  
    .message_item .content {
      display: block;
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {

    .box_rent {
      display: flex;
      flex-direction: column;
    }
    .lowres{
      position: relative;
      bottom:350px;
    }
    .rent_main{
      min-height: 760px;
    }
    .rented_numbers_main {
      top:400px;
      min-height: 355px;
      max-height: 700px;
    }
  
    .select_rented_number {
      padding-right: 0;
    }
    .rented_messages ul {
      overflow-y: auto;
      max-height: 620px;
    }
    .rented_numbers_main .numbers_list {
      border-right: none;
    }
  
    .message_item .content {
      display: block;
    }
  }
  