.btn.outline-primary {
    border: 2px solid #00a990;
    color: #00a990;
}

.outline-primary:hover {
    border: 2px solid #00a990 !important;
    color: #00a990 !important;
}

.btn.primary {
    border: 1px solid #00a990;
    color: #fff;
    background-color: #00a990;
}

.primary:hover {
    border: 1px solid #00a990 !important;
    color: #fff !important;
    background-color: #00a990 !important;
}

input[type=checkbox] {
    border-color: #00a990 !important;
}

input[type=checkbox]:checked {
    color: #00a990 !important;
    background-color: #00a990 !important;
}

.cookies-center {
    position: fixed;
    width: 35%;
    min-width: 350px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 12px rgb(0, 0, 0, 0.3);
    border-radius: 16px;
    z-index: 1000;
}

@media only screen and (min-width: 769px) {
    .cookies {
        padding: 20px;
        background-color: #fff;
        position: fixed;
        z-index: 1000;
        right: 50px;
        bottom: 50px;
        box-shadow: 0px 4px 12px rgb(0, 0, 0, 0.3);
        border-radius: 16px;
        min-width: 350px;
        width: 35%;
    }
    
}