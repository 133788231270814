.listCard {
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
}

.listCard2 {
  box-sizing: border-box;
  background: linear-gradient(
    16.35deg,
    #e8e0e3 10.18%,
    #d8e6ef 31.15%,
    #c9ecfb 57.36%,
    #c5eeff 80.96%
  );
  opacity: 0.9;
  border-radius: 10px;
  height: 437px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.changePass {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-left: 22px;
  margin-bottom: 40px;
}

.maring-bottom-45 {
  margin-bottom: 45px;
}

.backG {
  background: #f8f8f8 !important;
  border-radius: 8px !important;
}

.btnColorChange {
  background-color: #00a990 !important;
  border-color: #00a990 !important;
  border-radius: 8px !important;
  width: 560px;
  height: 50px;
}

.btnColorChange2 {
  background-color: #00a990 !important;
  border-color: #00a990 !important;
  border-radius: 8px !important;
  padding: 13px;
  min-height: 50px;
  word-break: break-all;
}

.btnColorChange3 {
  background-color: #00a990 !important;
  border-color: #00a990 !important;
  border-radius: 8px !important;
  padding: 13px;
  min-height: 50px;
  word-break: break-all;
  text-align: center !important;
}

.greenPWD {
  width: 100%;
}

.logoImage {
  position: relative;
  top: 40px;
  width: 200px;
  height: 200px;
}

.getKEY {
  color: white;
  width: 100%;
}

i.eye3 {
  display: flex;
  align-items: center;
  position: absolute;
  height: 45%;
  top: 0;
  right: 0;
  padding-right: 24px;
  cursor: pointer;
  color: #6c757d;
}

i.eye4 {
  display: flex;
  align-items: center;
  position: absolute;
  height: 45%;
  top: 0;
  right: 0;
  padding-right: 24px;
  cursor: pointer;
  color: #6c757d;
}

i.eye5 {
  display: flex;
  align-items: center;
  position: absolute;
  height: 45%;
  top: 0;
  right: 0;
  padding-right: 24px;
  cursor: pointer;
  color: #6c757d;
}
.change-password-min-height {
  min-height: 81.1vh;
}

.center-payment-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
