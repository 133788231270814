.text-align-end {
  text-align: end;
}

.ReactModal__Overlay {
  z-index: 100;
}

.background-grey {
  background-color: darkgray;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.text-white {
  color: white;
}

.margin-top-20 {
  margin-top: 20px !important;
  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #000000;
}

.margin-top-10 {
  margin-top: 15px !important;
}

.margin-10-top {
  margin-top: 10px !important;
}

.lineHeight {
  line-height: 2rem;
}

.margin-6-top {
  margin-top: 6px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.list {
  list-style-type: none;
  padding: 10px;
}

.text-align-start {
  text-align: start;
}

.social-cont1 {
  margin: 0;
  top: 550%;
}

.hide {
  display: none !important;
}

.data-col h5 {
  text-align: start;
}

.height0 {
  height: 0;
}

.padding-left-50 {
  padding-left: 50px !important;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-bottom-45 {
  padding-bottom: 45px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-left-110 {
  padding-left: 110px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-left-100 {
  margin-left: 110px;
}

.margin-left-45 {
  margin-left: 45px !important;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.lastDiv {
  /* width: 1050px;
    height: 500px; */
  margin-top: 25px !important;
  padding-top: 15px;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
}

.white-box {
  width: 717px;
  height: 103px;
  background: #ffffff;
  border-radius: 8px;
}

.padding-top-7 {
  padding-top: 7px;
}

.padding-bottom-20 {
  padding-bottom: 15px;
}

.PVA {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  /* or 21px */

  text-transform: capitalize;
  margin-bottom: 15px;

  color: #000000;
  padding-top: 15px;
}

.no-border {
  border: none;
}

.margin-top-6 {
  margin-top: 6px;
}

.overflow {
  /* overflow: hidden; */
  overflow-y: auto;
  max-height: 80%;
  scrollbar-width: thin !important;
}

.countryListNew {
  box-sizing: border-box;
  width: 100%;
  /* height: 364px;
    left: 387px;
    top: 475px; */
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  /* transform: rotate(-180deg); */
  /* margin-bottom: 15px; */
}

.frame {
  position: absolute;
  width: 228px;
  height: 240px;
  left: 650px;
  top: 140px;
}

.detail1 {
  position: absolute;
  width: 460px;
  height: 189px;
  left: 1140px;
  top: 187px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  color: #000000;
}

.text2 {
  position: absolute;
  width: 961px;
  height: 17px;
  left: 650px;
  top: 420px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;

  color: #000000;
}

.margin-left-22 {
  margin-left: 22px;
}

.eclipse {
  width: 39px !important;
  height: 39px;
  background: #ffffff;
  border-radius: 50px 50px 50px 50px;
  box-shadow: 0px 0px 42px 1px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-top: 2px;
  font-size: 22px;
  color: #00a990;
  font-weight: 600;
}

.eclipse2 {
  position: absolute;
  width: 39px;
  height: 39px;
  left: 655px;
  top: 515px;

  background: #ffffff;
  border-radius: 50px 50px 50px 50px;
  box-shadow: 0px 0px 42px 1px rgba(0, 0, 0, 0.1);
}

.eclipse3 {
  position: absolute;
  width: 39px;
  height: 39px;
  left: 655px;
  top: 565px;

  background: #ffffff;
  border-radius: 50px 50px 50px 50px;
  box-shadow: 0px 0px 42px 1px rgba(0, 0, 0, 0.1);
}

.num {
  position: absolute;
  width: 39px;
  height: 39px;
  left: 655px;
  top: 468px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #00a990;
}

.num2 {
  position: absolute;
  width: 39px;
  height: 39px;
  left: 655px;
  top: 518px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #00a990;
}

.num3 {
  position: absolute;
  width: 39px;
  height: 39px;
  left: 655px;
  top: 568px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #00a990;
}

.numText {
  position: absolute;
  width: 390px;
  height: 39px;
  left: 690px;
  top: 468px;

  font-family: "Arial";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 19px;
  line-height: 150%;
  text-align: center;
  /* color: #00A990; */
}

.numText2 {
  position: absolute;
  width: 500px;
  height: 39px;
  left: 710px;
  top: 518px;

  font-family: "Arial";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 19px;
  line-height: 150%;
  text-align: center;
  /* color: #00A990; */
}

.numText3 {
  position: absolute;
  width: 500px;
  height: 39px;
  left: 620px;
  top: 568px;

  font-family: "Arial";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 19px;
  line-height: 150%;
  text-align: center;
}

.text1 {
  position: absolute;
  width: 944px;
  height: 22px;
  left: 1140px;
  top: 141px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 22px;
  /* identical to box height */

  color: #000000;
}

.info {
  position: absolute;
  width: 1050px;
  height: 903px;
  left: 610px;
  top: 100px;

  background: linear-gradient(
    16.35deg,
    #e8e0e3 10.18%,
    #d8e6ef 31.15%,
    #c9ecfb 57.36%,
    #c5eeff 80.96%
  );
  opacity: 0.5;
  border-radius: 10px;
  clear: both;
}

.info2 {
  position: absolute;
  width: 1050px;
  height: 903px;
  left: 610px;
  top: 325px;

  background: linear-gradient(
    16.35deg,
    #e8e0e3 10.18%,
    #d8e6ef 31.15%,
    #c9ecfb 57.36%,
    #c5eeff 80.96%
  );
  opacity: 0.5;
  border-radius: 10px;
}

.info3 {
  /* margin-left: 10px !important; */
  margin-top: 2px !important;
  padding-top: 25px;
  padding-bottom: 25px;
  background: linear-gradient(
    16.35deg,
    #e8e0e3 10.18%,
    #d8e6ef 31.15%,
    #c9ecfb 57.36%,
    #c5eeff 80.96%
  );
  border-radius: 10px;
}

.serviceListNew {
  box-sizing: border-box;

  width: 100%;
  /* height: 990px; */
  left: 387px;
  top: 475px;

  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 10px 10px;
}

.animatioj {
  transition: max-height 0.6s, padding 0.6s;
}

.logoImage {
  width: 351px !important;
  height: 315px;
}

.padding-top-10 {
  padding-top: 10px;
}

/* .data-row {
  margin-left: 10px !important;
} */

.flag {
  /* box-sizing: border-box; */

  /* position: absolute; */
  width: 40px;
  height: 27px;
  left: 118px;
  top: 196px;

  /* background: url(russia.png); */
  /* border: 1px solid #F4F4F4; */
  /* border-radius: 2px; */
}

.countryDataNew {
  /* position: absolute; */
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  /* background: #FAFAFA; */
  /* opacity: 0; */
  border-radius: 8px;
}

.newActive {
  /* position: absolute; */
  width: 100%;
  height: 45px;
  left: 110px;
  top: 188px;

  background: #f0f2f6;
  border-radius: 8px;
}

.activeOrders {
  /* box-sizing: border-box; */
  /* width: 1050px; */
  background: #ffffff;
  margin-bottom: 10px;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
}

.countryDataNew:hover {
  background: #f0f2f6;
}

.data-col {
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.data-col:hover {
  border: 2px solid black;
}

.dis-block {
  display: block !important;
}

.buy-btn {
  padding-right: 0px !important;
}

.text4 {
  position: absolute;
  width: 716px;
  height: 21px;
  left: 595px;
  top: 630px;

  font-family: "Arial";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 19px;
  line-height: 150%;
  text-align: center;
  /* identical to box height, or 21px */

  color: #000000;
}

.rectangle {
  box-sizing: border-box;

  position: absolute;
  width: 1050px;
  height: 475px;
  left: 610px;
  top: 1020px;

  background: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
}

.rectangle2 {
  box-sizing: border-box;

  position: absolute;
  width: 1050px;
  height: 475px;
  left: 610px;
  top: 1240px;

  background: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
}

.visible-scrollbar,
.invisible-scrollbar,
.mostly-customized-scrollbar {
  display: block;
  width: 10em;
  overflow: auto;
  /* height: 2em; */
  max-height: 80%;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

.height-0 {
  height: 0px !important;
}

.height-50 {
  height: 55px !important;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.padding-left-60 {
  padding-left: 60px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.margin-left-28 {
  margin-left: 35px;
}

.fontFam {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.lastDiv {
  background: #ffffff;
  border-radius: 8px;
}

.lastDiv2 {
  width: 94%;
  margin-left: 3%;
  margin-bottom: 1rem;
}

.maintxt {
  position: absolute;
  margin-left: 15%;
}

.Tit1 {
  font-size: 25px;
}

@media (max-width: 1199px) and (min-width: 768px) {
  .firstPic img {
    width: 100%;
  }
}

.countryList1 {
  position: relative;
}

.countryName {
  position: relative;
  text-align: start;
  right: 10px;
}

.activeOrder {
  text-align: center;
  position: relative;
  top: 5px;
  right: -5px;
  font-size: 33px !important;
}

.logoLoading {
  position: relative;
  width: 30px;
  left: 0px;
}

.lastElemStat:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.qtypos {
  position: relative;
  bottom: 4px;
}

.countryStyle {
  position: relative;
  text-align: left;
  top: 3px;
}

.indexNum {
  position: relative;
  bottom: 27px;
  left: calc(100%);
}

@media (max-width: 1400px) {
  .responsive {
    font-size: medium;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .servicelist-height {
    height: 1310px !important;
  }
  .instructions-main {
    padding-bottom: 172px !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .servicelist-height {
    height: 1455px !important;
  }
  .instructions-main {
    padding-bottom: 44px !important;
  }
}
/* css jan 12  */

.heading-country {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  color: #333333;
  margin: 0;
}

.select-country {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 15px 0;
}

.search-country-input {
  font-family: "Roboto", sans-serif;
  border: 2px solid #00abb3 !important;
  border-radius: 15px !important;
  background: #ffffff;
  color: #231f20;
  font-weight: 400;
  font-size: 13px;
  max-width: 260px;
  width: 100%;
  height: 30px;
  padding: 0 9px;
  text-transform: capitalize;
}

.search-country-input::placeholder {
  color: #cccccc !important;
  font-weight: 400;
  font-size: 13px;
}

.search-country-input:focus {
  outline: none !important;
}

.counties-name-flag {
  display: inline-flex;
  align-items: center;
  gap: 7px;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  padding: 4px;
  cursor: pointer;
}

.All-country-list {
  gap: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.service-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  padding: 4px 6px;
  margin-top: 11px !important;
  cursor: pointer;
}

.service-name-count span {
  font-family: "Roboto", sans-serif;
  color: #333333;
  font-weight: 400;
  font-size: 13px;
}

.service-buy-price span {
  font-family: "Roboto", sans-serif;
  color: #333333;
  font-weight: 400;
  font-size: 13px;
}

span.service-buy-tag {
  background: #06890b;
  padding: 1px 8px;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  border-radius: 18px;
}

.countrylist-height {
  height: 251px !important;
  overflow-y: scroll;
}

.tabs-content {
  background: #ffffff;
  border-radius: 20px;
}

.tabs-design .MuiTabs-flexContainer.MuiTabs-flexContainer {
  display: flex;
  justify-content: end;
}

.tabs-design .MuiTabs-root .MuiButtonBase-root.MuiTab-root {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 17px !important;
  text-transform: capitalize !important;
  padding-bottom: 0;
}

.active-tab {
  color: #00abb3 !important;
}

.unactive-tab {
  color: #3c4048 !important;
}

.tabs-design span.MuiTabs-indicator.MuiTabs-indicator {
  bottom: 2px !important;
  background-color: #00abb3 !important;
}

.country-flags {
  width: 19px;
  height: 19px;
  object-fit: cover;
  border-radius: 100px;
}

.activeCountry {
  background: #f0f2f6;
}

.servicelist-height {
  height: 1162px;
  padding-bottom: 22px;
  overflow-y: scroll;
}

.instructions-main {
  padding-bottom: 60px;
  /* padding-bottom: 158px; */
}
/* 16-01-2023 */
.Homepage {
  padding-bottom: 17px;
  padding-top: 20px;
  border: none;
}
.tabnavbar button {
  padding: 0;
  margin: 0 7px;
}
.privacy-content li {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}

.countrylist-height::-webkit-scrollbar {
  width: 5px;
}

.countrylist-height::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #00a990d1;
}

.countrylist-height::-webkit-scrollbar-thumb {
  background-color: #00a990;
  border-radius: 100px;
}

.servicelist-height::-webkit-scrollbar {
  width: 5px;
}

.servicelist-height::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #00a990d1;
}

.servicelist-height::-webkit-scrollbar-thumb {
  background-color: #00a990;
  border-radius: 100px;
}
.servicelistscrollbar {
  padding-right: 11px;
}
button.service-buy-tag {
  background: #06890b;
  padding: 1px 8px;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  border-radius: 18px;
  border: unset;
}
.service-content.newActive,
.activeCountry.newActive {
  height: inherit;
}
.same-space-bottom {
  margin-bottom: 15px;
}

button.service-buy-tag {
  background: #06890b;
  padding: 1px 8px;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  border-radius: 18px;
  border: unset;
}
.service-content.newActive,
.activeCountry.newActive {
  height: inherit;
}
.show.margin-top-10.same-space-bottom {
  padding: 0 14px;
}
