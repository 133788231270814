.top-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
  font-size: 25px;
  color: #231f20;
}


.first-description p,
.third-description p {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  margin: 0 !important;
}

.first-description {
  padding-top: 28px;
}

.third-description {
  padding: 28px 0;
}

.two-fa {
  color: #4ca9b2;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.two-pva {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.overview-all-headings {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  margin: 0;
  line-height: 27px;
}

.steps-description p {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  font-size: 16px;
}

.steps-description {
  padding: 26px 0;
}

.all-steps {
  display: flex;
  align-items: center;
  gap: 11px;
}

.all-steps p {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.all-steps {
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 10px 0;
}

.online-numbers {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 19px 0 25px;
}

.activation-rent {
  display: flex;
  gap: 11px;
  align-items: center;
}

.activation-box {
  background: #ddf3f5;
  border-radius: 70px;
  padding: 26px 39px 26px;
  margin: 28px 0;
}

.activation-rent span {
  font-style: normal;
  color: #333333;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 2px;
  margin-top: 5px;
}
.discover-content {
  padding: 40px 0 18px;
}

.discover-heading {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 30px 0;
  line-height: 27px;
}

.help-support {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.virtual-number {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  padding: 30px 0;
  margin: 0;
}

.number-json {
  color: #2329c3;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.sign-up-btn {
  background: #0e7ee4;
  border-radius: 50px;
  padding: 14px 16px;
  border: none;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
}

.two-fa-head {
  color: #4ca9b2;
}
