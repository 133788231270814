.thanktext {
  max-width: 500px;
  background: #fff;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 1px 1px 1px #dddddd4d;
  border-radius: 8px;
  text-align: center;
}

.thankyoupage {
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
