.footer-content {
  background: #f8f8f8;
  padding: 25px 17px;
  border-top: 1px solid #e2e2e2;
}

.footer-content p {
  text-align: center;
  color: #333333;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.link-decoration {
  text-decoration: none;
  color: #00A990;
}

.link-decoration:hover {
  color: #00A990;
}
