#dropdown-basic {
  padding: 5px 15px !important;
  border-radius: 35px !important;
  font-size: 16px !important;
  cursor: pointer !important;
  background-color: white;
  border-color: rgb(219, 219, 219);
  color: rgb(62, 60, 60);
}

.dropdown-menu {
  border-radius: 30px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important;
  padding: 10px !important;
  margin: 0 -10px  !important;
  margin-right: -10px !important;
}

.dropdown-menu a {
  color: #333 !important;
  text-decoration: none !important;
  display: block !important;
  padding: 8px 10px !important;
  font-size: 16px;
}

.dropdown-menu a:hover {
  color: #333 !important;
  text-decoration: none !important;
  display: block !important;
  padding: 8px 10px !important;
  background-color: #e2f2f0;
  border-radius: 30px;
}


@media only screen and (max-width: 576px) {
  button.navbar-toggler {
    padding: 4px 6px;
  }
  
  .navbar-toggler-icon {
    width: 15px !important;
  }
  
  
  #dropdown-basic {
    padding: 5px !important;
  }
  
}

@media only screen and (max-width: 349px) {

  .newLogo {
    width: 100px !important;
  }
  
}

@media only screen and (max-width: 400px) {
  
  #dropdown-basic {
    font-size: 12px !important;
  }

  .dolsCSS {
    font-size: 18px !important;
  }

  .balance_amount {
    font-size: 12px !important;
  }

  .balance {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  
}
