.des-content {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  margin: 0;
  padding: 28px 0;
}

.act-content {
  padding: 28px 0;
}

.act-content ol li,
.act-content ul li {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}

.instruction-heading {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 27px;
}

.privacy-content {
  padding-top: 28px;
}

.instructions-fourth-section p {
  padding-top: 28px;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  margin: 0;
}
