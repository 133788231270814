i.eye1 {
  display: flex;
  align-items: center;
  position: absolute;
  height: 60%;
  top: 13px;
  right: 0;
  padding-right: 24px;
  cursor: pointer;
  color: #6c757d;
}

i.eye2 {
  display: flex;
  align-items: center;
  position: absolute;
  height: 60%;
  top: 9px;
  right: 0;
  padding-right: 24px;
  cursor: pointer;
  color: #6c757d;
}
.signuppage {
  margin-bottom: 10px;
  margin-left: 40px;
}
.Passwordp {
  margin: -29px 0 11px !important;
}
span.signupimg {
  width: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -6px 0 0;
}
.btngoogleicon {
  border: none;
  background: none;
  padding: 0;
}
.sigupcaptcha {
  margin: -20px 0 -10px;
}
.inputFieldsig {
  margin: 10px 0;
}
.form-control:focus {
  box-shadow: unset !important;
}

.ReactModalPortal .ReactModal__Content {
  border-radius: 15px !important;
}



.custom-button-style {
  background-color: #00abb3 !important;
}
.already-registered-text {
  font-size: 17px !important;
}
.sign-up-title {
  font-size: 37px !important;
}
.captcha-container {
  display: flex;
  justify-content: center;
}

.hcaptcha {
  display: inline-block;

}
