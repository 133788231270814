.App {
  background-color: #eefdfd;
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.error_msg {
  color: red;
  position: relative;
  bottom: -15px;
}

.sweet-loading {
  display: block;
  margin: 0 auto;
  border-color: red;
}

.success_msg {
  color: green;
}

.margin-bottom-10 {
  margin-bottom: 15px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.6;
  z-index: 100;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
}

.table-card {
  margin-top: 32px !important;
  background-color: white;
  border-radius: 10px;
  padding: 30px 25px !important;
}

.navbar-toggler:focus {
  box-shadow: unset !important;
}
h2.tilte-privacy {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 47px;
  display: flex;
  align-items: center;
  color: #000000;
  text-align: center;
  justify-content: center;
  padding: 10px 0 10px;
}
.privacypage p {
  line-height: 36px;
}
.desone {
  padding-top: 10px;
}
.desone-des1 {
  margin: -18px 0 18px;
}
.retuen1 {
  margin: 0;
}
.conditions {
  margin: 0;
}
.termsdes {
  margin: 30px 0 14px;
}
.termsdesone {
  margin: 25px 0 0;
}

.forgetpasswords {
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forgetpasswordsform {
  background: #fff;
  padding: 30px 47px;
  max-width: 450px;
  margin: 30px 0;
  width: 450px;
  border-radius: 8px;
}
.Forgetbtn {
  background-color: #00abb3 !important;
  border-color: #00abb3 !important;
  width: 100%;
  color: #fff !important;
  margin-bottom: 35px;
}
.form_sec input {
  height: 47px;
  border: 1px solid #dddddd96;
}
h1.title2Forget {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  margin: 1px 0 18px;
  text-align: center;
}
.table-min-heightforget {
  min-height: 85vh;
}
.forgetpasswordsform .success_msg {
  color: green;
  font-size: 17px;
}
.dropdown-new-class {
  border-radius: 8px !important;
  padding: 0 0 !important;
}
.dropdown-new-class .locSet {
  left: 0;
}
.dropdown-new-class .dropdown-divider {
  margin: 0;
}
.dropdown-new-class .locSet {
  border-radius: 8px;
}
.dropdown-new-class .locSet:hover {
  border-radius: 8px;
}
.dropdown-new-class span.btn.dropdown-item {
  padding: 10px 13px;
}
