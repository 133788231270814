.title1 {
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-style: small-caps;
  font-weight: bold;
  font-size: 33px;
  color: #079b8e;
  margin-bottom: 20px;
  /* margin-top: 55px; */
  align-items: center;
}
.namebox {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 60px 20px 40px;
}
.question {
  font-family: "Roboto", sans-serif;
}
.answer {
  text-align: justify;
  font-family: "Roboto", sans-serif;
}
.title2 {
  color: #6c757d;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  margin-bottom: 10px;
}
.faq-accordion {
  width: 80%;
  margin: 0 auto;
}

.faq-accordion {
  width: 80%;
  margin: 0 auto;
}

.title2 {
  height: 97px;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 82px;
  align-items: center;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.title3 {
  font-style: normal;
  font-size: 25px;
  align-items: center;
  color: #000000;
}

.table-min-height.help {
  margin-top: 0px !important;
  min-height: 95vh;
}
@media (min-width: 320px) and (max-width: 768px) {
  .namebox {
    padding: unset;
  }
  .title2 {
    height: auto !important;
    font-size: 22px !important;
    line-height: 30px !important;
    margin-bottom: 25px;
  }
  .faq-accordion.accordion {
    width: 100%;
  }
  .title1 {
    justify-content: center;
    font-family: "Roboto", sans-serif;
    font-style: small-caps;
    font-weight: bold;
    font-size: 40px;
    color: #079b8e;
    margin-bottom: 10px;
    margin-top: 0;
    align-items: center;
  }

  .title3 {
    font-style: normal;
    font-size: 20px;
    align-items: center;
    color: #000000;
  }
}
