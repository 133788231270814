.thanks-bg {
    text-align: center;
    min-height: calc(100vh - 160px);
}

.success-icon {
    text-align: center;
    width: 100px;
}

.success-text {
    color: #2F6C36;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    padding-top: 20px;
    padding-bottom: 20px;
}

.success-description {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 30px;
}

.btn-home-page {
    background-color: #00a990!important;
    border-color: #00a990!important;
    border-radius: 8px!important;
    height: 50px;
    width: 100%;
    max-width: 450px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.thanksBG {
    background: #FFF;
}