.support-headings {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #333333;
}

.note-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #333333;
  padding-top: 28px;
}

.support-second-section p {
  padding-top: 28px;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  margin: 0;
}
p.remove-top-space {
  padding-top: 0;
}
