.footerpages-height {
  min-height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerall-content h1 {
  font-family: "Roboto", sans-serif;
  color: #333333;
}

.title2 {
  height: 97px;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 82px;
  align-items: center;
  color: #000000;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  text-shadow: unset !important;
}

.same-space-bottom- {
  margin-top: 0 !important;
  padding-bottom: 60px !important;
  min-height: 90vh;
}
