.error_msg{
  color: rgb(247, 28, 28);
  text-align: center;
  font-size: 15px;
}


  .ReactModal__Overlay--after-open {
    background-color: rgba(192,192,192,0.7) !important;
  }


  .alert-danger{
    --bs-alert-border-color: none !important;
  }
  