.click {
  cursor: pointer;
  height: 25px;
}
.click2 {
  cursor: pointer;
  height: 25px;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 5px;
  text-align: center;
}

.orderListTable {
  overflow-y: auto;
}

.orderListTable table {
  min-width: 900px;
}

table .code {
  word-break: break-all;
  max-width: 350px;
}
th {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.lastEl:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
tr {
  background-color: rgb(245, 245, 245);
}
tr.locTR:hover {
  background-color: rgb(255, 255, 255);
}
.listEnd {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.logoSize {
  position: relative;
  left: 0px;
  width: 30px;
}

.table-min-height {
  min-height: 80vh;
}
