.title_free
{

    font-family: 'Exo 2', sans-serif;
    font-size: 37px;
    font-weight: 700;
    line-height: 44px;
    margin-top: 60px;
    margin-bottom: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #00ABB3;


}

.freenumBG {
    background: #FFF;
    min-height: calc(100vh - 160px);
}

.box_please{
/* Testimonial /


/ Auto layout /

display: flex;
flex-direction: column;
align-items: flex-start;
padding: 40px;
gap: 40px;
justify-content: center;
position: absolute;
width: 1099px;
height: 836px;
left: 170px;
top: 271px;

background: #FFFFFF;
box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.12);
border-radius: 12px;

}
.title_free_scnd{
    / Body /


position: absolute;
width: 115px;
height: 27px;
left: 229px;
top: 307px;
flex-direction: column;
font-family: 'Exo 2';
font-style: normal;
font-weight: 600;
font-size: 19px;
line-height: 140%;
/ identical to box height, or 27px /


color: #00ABB3;
}
.text_free{
    flex-direction: column;
position: absolute;
width: 985px;
height: 229px;
left: 227px;
top: 354px;

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 45px;
/ or 281% */

    text-align: justify;
    color: #333333;
}

body {
    background: #FFF !important;
}

.sub_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: #4B4D4F;
    text-align: center;
    margin-bottom: 40px;
}

.title_free_scnd {
    font: 600 19px/25px 'Exo 2', sans-serif;
    color: #00ABB3;
}

.text_free {
    font: 400 16px/40px 'Roboto', sans-serif;
    color: #333333;
}

.danger-text {
    color: #BC1616;
}

.box_please {
    border: none !important;
    border-radius: 10px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    padding: 25px;
    padding-bottom: 50px;
    margin-bottom: 70px;
}

.free_numbers_main {
    margin: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    max-height: 400px;
}

.free_numbers_main ul {
    list-style-type: none;
    padding-left: 0;
}

.free_numbers_main .numbers_list {
    overflow-y: auto;
}

.free_numbers_main .numbers_list li {
    display: flex;
    padding: 10px 5px 10px 0;
    border-bottom: 1px solid rgba(217, 217, 217,.4);
    align-items: center;
    cursor: pointer;
}

.free_numbers_main .numbers_list li:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.free_numbers_main .numbers_list {
    padding: 10px 20px;
    border-right: 1px solid #D9D9D9;
    height: 100%;
}

.flag_icon {
    width: 35px;
    height: 23px;
    display: block;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
    margin-right: 20px;
}

.flag_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.free_numbers_main .phone_number {
    color: #303339;
    font: 400 17px/17px 'Amaranth', sans-serif;
}

.free_numbers_main .phone_number.active {
    color: #0E7EE4;
}

.number_header {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 15px 5px 15px 15px;
    border: 1px solid #ECE9E9;
    justify-content: space-between;
    margin-bottom: 15px;
}

.number_header .left, .number_header .right {
    display: flex;
    align-items: flex-end;
}

.number_header .left {
    font: 500 15px/15px 'Roboto', sans-serif;
    color: #000;
}

.select_free_number.empty .number_header .left * {
    display: none;
}

.select_free_number.empty .number_header .left {
    padding-bottom: 25px;
    width: 130px;
    border-radius: 999px;
    background: #F0F0F0;
}

.select_free_number.empty .number_header .right * {
    display: none;
}

.select_free_number.empty .number_header .right {
    padding-bottom: 25px;
    width: 90px;
    margin-right: 10px;
    border-radius: 999px;
    background: #F0F0F0;
}

.number_header .left button {
    background: none;
    display: block;
    border: none;
    outline: none;
    font-size: 22px;
    line-height: 0;
    height: 19px;
}

.number_header .left button i {
    line-height: 0;
    height: 19px;
}

.select_free_number {
    padding: 15px 15px 0px 0px;
}

.messages ul {
    overflow-y: auto;
    height: calc(400px - 100px);
    margin-bottom: 0;
}

.messages ul::-webkit-scrollbar, .free_numbers_main .numbers_list::-webkit-scrollbar {
    width: 5px;
}

.messages ul::-webkit-scrollbar-thumb, .free_numbers_main .numbers_list::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 100px;
}

.number_header .right .phone_number {
    line-height: 22px;
}

.message_item {
    padding: 20px 20px 20px 10px;
    display: flex;
    border-bottom: 1px solid rgba(217, 217, 217, .4);
}

.message_item:last-child {
    border-bottom: none;
}

.message_item .icon {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background: #F0F0F0;
}

.message_item .content {
    width: calc(100% - 31px);
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding-left: 15px;
}

.message_item .content .left {
    font-size: 15px;
}

.message_item .content .left .from {
    font-family: 'Amaranth', sans-serif;
    color: #000;
    font-size: 14px;
    margin-bottom: 7px;
}

.select_free_number.empty .message_item .content .left .from {
    width: 80px;
    padding-bottom: 15px;
    background: #F0F0F0;
    border-radius: 999px;
    margin-bottom: 12px;
}

.select_free_number.empty .message_item .content .left .body {
    width: 120px;
    padding-bottom: 20px;
    background: #F0F0F0;
    border-radius: 999px;
}

.message_item .content .date {
    font-family: 'Roboto', sans-serif;
    color: #000;
}

.message_item .content .date {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    color: #000;
}

.message_item .content .date {
    margin-bottom: 7px;
}

.select_free_number.empty .message_item .content .date {
    width: 70px;
    padding-bottom: 15px;
    background: #F0F0F0;
    border-radius: 999px;
    margin-bottom: 12px;
}

.select_free_number.empty .message_item .content .right .code {
    width: 70px;
    padding-bottom: 20px;
    background: #F0F0F0;
    border-radius: 999px;
}

.message_item .content .right .code span {
    font-weight: 700;
    color: #2329C3;
}

.numbers_list {
    max-height: 400px !important;
}

@media screen and (max-width: 575.98px) {
    .box_please {
        padding: 7px;
        padding-bottom: 10px;
    }

    .free_numbers_main {
        margin: 0px;
    }

    .number_header {
        display: block;
        margin-bottom: 15px;
        width: 100%;
        border-radius: 0;
    }

    .number_header .left {
        margin-bottom: 10px;
    }

    .number_header .right {
        justify-content: flex-end;
    }

    .number_header .right .flag_icon {
        margin-right: 5px;
    }

    .select_free_number {
        padding-right: 0;
    }

    .message_item .content {
        display: block;
    }

    .message_item .content .left .body {
        margin-bottom: 10px;
    }

    .message_item .content .date {
        margin-bottom: 0;
    }

    .free_numbers_main {
        max-height: 1000px;
    }

    .free_numbers_main .numbers_list {
        border-right: none;
    }

    .numbers_list {
        max-height: 300px !important;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .box_please {
        padding: 7px;
        padding-bottom: 10px;
    }

    .free_numbers_main {
        margin: 0px;
    }

    .number_header {
        display: block;
        margin-bottom: 15px;
        width: 100%;
        border-radius: 0;
    }

    .number_header .left {
        margin-bottom: 10px;
    }

    .number_header .right {
        justify-content: flex-end;
    }

    .number_header .right .flag_icon {
        margin-right: 5px;
    }

    .select_free_number {
        padding-right: 0;
    }

    .message_item .content {
        display: block;
    }

    .message_item .content .left .body {
        margin-bottom: 10px;
    }

    .message_item .content .right .date {
        margin-bottom: 0;
    }

    .free_numbers_main {
        max-height: 1000px;
    }

    .free_numbers_main .numbers_list {
        border-right: none;
    }

    .numbers_list {
        max-height: 300px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .free_numbers_main {
        max-height: 700px;
    }

    .select_free_number {
        padding-right: 0;
    }

    .free_numbers_main .numbers_list {
        border-right: none;
    }

    .message_item .content {
        display: block;
    }
}