.btn-payment {
  background: #fff;
  color: #4b4d4f;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 7px 25px;
  height: 50px;
  font-weight: 600;
  display: none
}

.title1 {
  font-size: 30px;
  font-weight: 800;
}

.btn-payment-selected {
  background: #fff;
  color: #00a990;
  border: 1px solid #00a990;
  border-radius: 6px;
  padding: 7px 25px;
  height: 50px;
  font-weight: 600;
}
.instruction-text {
  font-family: montserrat, sans-serif;
  color: #4b4d4f;
}

.swiper-button-next {
  right: auto;
  left: 100%;
}
.payelogo {
  margin-bottom: 0;
}
/* .payetitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #4b4d4f;
  text-align: center;
  margin-bottom: 10px;
} */
.prev-button,
.next-button {
  width: 50px;
  height: 50px;
  border-radius: 16px;
  font-size: 25px;
  background: none;
  border: 1px solid #000;
  color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
}

.prev-button:hover,
.next-button:hover {
  border-color: #00abb3;
  color: #00abb3;
}

.prev-button:disabled,
.next-button:disabled {
  opacity: 0.3;
  border: 1px solid #000 !important;
  color: #000 !important;
}

.prev-button {
  left: 100%;
}

.next-button {
  right: 100%;
}

.swiper-wrapper {
  align-items: stretch;
}

.swiper-slide {
  height: auto;
}

.payment-option {
  text-align: center;
  padding: 10px;
  height: 100%;
  cursor: pointer;
  /* border: 1px solid #f5f5f5; */
}

.payment-option img {
  height: 70px;
  padding: 5px 60px;
}

.payment-option:hover {
  color: #fff;
  /* border: 1px solid #00a990; */
  cursor: pointer;
}
.swiper-wrapper {
  justify-content: center !important;
}

.payment-option-selected {
  color: #fff;
  /* border: 1px solid #00a990; */
  cursor: pointer;
}

.paymentBG {
  background: #fff !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Proceedbtn {
  text-align: center !important;
  width: 100% !important;
}
/* .swiper {
  padding: 0 122px !important;
  margin: 0 32px !important;
} */

.payelogoicon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-option-selected img {
  /* border: 1px solid #00a990;
  padding: 5px 60px; */
}

.payment-option-selected .payelogoicon {
  border: 1px solid #00a990;
}
.payelogoicon {
  border: 1px solid transparent;
}
/* .swiper-slide {
  width: 160px !important;
} */

/* .swiper-backface-hidden {
  padding: 0 0 0 56px;
} */
/* .slick-prev,
.slick-next {
  background: black !important;
} */
ul.slick-dots {
  display: none !important;
}
.slick-prev,
.slick-next {
  width: 50px;
  height: 50px;
  border: 1px solid #000;
  border-radius: 10px;
}
.slick-prev::before {
  content: url(https://2fapva.com/img/left.svg) !important;
}
.slick-next:before {
  content: url(https://2fapva.com/img/right.svg) !important;
}
@media screen and (max-width: 575.98px) {
  .prev-button {
    left: auto;
    right: -10px;
    z-index: 99;
  }

  .next-button {
    right: auto;
    left: -10px;
    z-index: 99;
  }

  .btn-payment {
    padding: 7px 10px;
  }

  .btn-payment-selected {
    padding: 7px 10px;
  }
  .payment-option img {
    padding: 5px 31px !important;
  }
}

@media (max-width: 480px) and (min-width: 320px) {
  .small-imput label.form-label.popup-label {
    min-width: unset !important;
  }
}
@media (max-width: 768px) and (min-width: 576px) {
  .prev-button {
    left: auto;
    right: -10px;
    z-index: 99;
  }

  .next-button {
    right: auto;
    left: -10px;
    z-index: 99;
  }
}

.slick-prev {
  left: -55px;
}
.slick-next {
  right: -55px;
}
