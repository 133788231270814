@media (max-width: 767px) {
  .formsec1 {
    display: block !important;
  }
  .QRCode {
    width: 200px !important;
    margin: 17px auto 40px !important;
  }
  .formpaym {
    width: 100% !important;
  }
  h2.titledepositpage {
    font-size: 16px !important;
  }
  .payment-img {
    margin: 0 7px !important;
    width: 27px;
    height: 27px;
  }
}

.listCardNew {
  box-sizing: border-box;

  position: absolute;
  width: 732px;
  max-width: calc(100% - 24px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
}

.btnColorChangeDeposit {
  background-color: #00a990 !important;
  border-color: #00a990 !important;
  border-radius: 8px !important;
  height: 50px;
  width: 100%;
  max-width: 522px;
}

.amountG {
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  max-width: 700px;
}

.payment-proceed-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bi-currency-dollar {
  color: #000;
  font-size: 18px;
}

.paymentBG .table-card {
  margin-top: 28px !important;
}

.paymentBG {
  min-height: calc(100vh - 160px);
}
.mb-2 {
  margin-bottom: 2rem;
}

.formsec1 {
  display: flex;
  padding: 0px 0 0;
  float: left;
}
.formpaym {
  margin-left: 0;
  width: 75%;
}
.modal-content {
  max-width: 791px !important;
}
.popup-input {
  display: flex;
}
.popup-address {
  display: flex;
  flex-direction: column;
}

.popup-label {
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}
.popup-group {
  width: 70%;
}
.QRCode .mb-2 {
  width: 100% !important;
  max-width: 98% !important;
}
.QRCode {
  width: 28%;
  text-align: left;
  margin-right: 30px;
  align-self: center;
}

.modal-dialog {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
  margin: 0 auto !important;
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
}
/* h2.titledepositpage {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: #000000;
  margin-left: 12px;
  margin-bottom: 0;
} */
.payment-img {
  margin: 0 15px;
}

div#success {
  text-align: right;
}
.small-imput label.form-label.popup-label {
  min-width: 120px;
}

/* update */

h2.titledepositpage {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: #000000;
  margin: 0;
}

.modal-dialog .modal-content {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.18);
  border-radius: 15px;
}

.adress-input {
  border: 1px solid #cfcfcf !important;
  /* border-radius: 8px !important; */
  background: #ffffff;
  height: 48px;
}

.address-label {
  color: #4b4d4f;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 17px;
  margin-bottom: 5px !important;
}

.amount-input
{
  background: #ffffff;
  border: 1px solid #cfcfcf !important;
  border-radius: 0.375rem 0px 0px 0.375rem !important; 
  height: 35px;
  width: 50% !important;
}
.amount-usd {
  background: #ffffff;
  border: 1px solid #cfcfcf !important;
  border-radius: 8px !important; 
  height: 35px;
  width: 50% !important;
}
.amount-btc {
  color: #4b4d4f;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px;
}
.small-imput .popup-group {
  width: 50% !important;
}

@media (min-width: 765px) {
  .main-modal {
    width: 600px !important;
  }
}
 @media (max-width:765px) {
  .font-address {
    font-size: 11.5px !important;
  }
 }



